import React from 'react';
import Menu from '../menu/menu';

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <Menu />
    </aside>
  );
};

export default Sidebar;
