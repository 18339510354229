import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../layout/layout';
import Modal from '../components/modal/modal';

import './index.scss';

class Index extends Component {
  state = {
    isModalOpen: false,
    currentVideo: null,
  };

  openModal = event => {
    this.setState({
      currentVideo: event.currentTarget.getAttribute('data-timelapse'),
      isModalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      currentVideo: null,
      isModalOpen: false,
    });
  };

  render() {
    const { currentVideo, isModalOpen } = this.state;
    const { data } = this.props;
    const crosswords = data.allCockpitCrosswords.nodes;

    return (
      <Layout>
        <div className="puzzles">
          {crosswords.map(crossword => (
            <div className="puzzle" key={crossword.id}>
              <div className="puzzle-content">
                <div className="puzzle-media">
                  <Img
                    fixed={crossword.screenshot.value.childImageSharp.fixed}
                    alt="Puzzle grid"
                  />{' '}
                  {crossword.timelapse && (
                    <button
                      className="puzzle-timelapse"
                      onClick={this.openModal}
                      data-timelapse={crossword.timelapse.value.publicURL}
                    >
                      Timelapse Video
                    </button>
                  )}
                </div>
                <div className="puzzle-info">
                  <h3 className="puzzle-date">{crossword.puzzleDate.value}</h3>
                  <h2 className="puzzle-title">
                    {crossword.publisher.value.publisher.value}
                    {crossword.title && `: “${crossword.title.value}”`}
                  </h2>
                  <p className="puzzle-constructors">
                    By{' '}
                    {crossword.author.value.map((author, index) => (
                      <span key={author.author.value}>
                        {index !== 0 &&
                          crossword.author.value.length > 2 &&
                          ', '}
                        {crossword.author.value.length === index + 1 &&
                          crossword.author.value.length > 1 &&
                          ' and '}
                        {`${author.author.value}`}
                      </span>
                    ))}
                  </p>
                  <p className="puzzle-meta">
                    Solve&nbsp;Time:&nbsp;{crossword.solveTime.value};
                    Rating:&nbsp;
                    {crossword.rating.value / 2}/5;
                    Relative&nbsp;Difficulty:&nbsp;
                    {crossword.difficulty.value / 2}/5
                  </p>
                  {crossword.excerpt && (
                    <>
                      <hr />
                      <div
                        className="puzzle-excerpt"
                        dangerouslySetInnerHTML={{
                          __html:
                            crossword.excerpt.value.childMarkdownRemark.html,
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {isModalOpen && (
          <Modal handleClose={this.closeModal}>
            <video src={currentVideo} playsInline autoPlay muted controls />
          </Modal>
        )}
      </Layout>
    );
  }
}

export const query = graphql`
  {
    allCockpitCrosswords(
      sort: { fields: [puzzleDate___value], order: DESC }
      filter: { lang: { eq: "en" } }
    ) {
      nodes {
        id
        puzzleDate {
          value(formatString: "dddd, MMMM D, YYYY")
        }
        publisher {
          value {
            publisher {
              value
            }
          }
        }
        author {
          value {
            author {
              value
            }
          }
        }
        title {
          value
        }
        timelapse {
          value {
            publicURL
          }
        }
        screenshot {
          value {
            childImageSharp {
              fixed(width: 128) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
        solveTime {
          value
        }
        rating {
          value
        }
        difficulty {
          value
        }
        excerpt {
          value {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;

export default Index;
