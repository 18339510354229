import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';

class Menu extends Component {
  state = {
    isMenuOpen: false,
  };

  toggleMenu = () => {
    const { isMenuOpen } = this.state;
    this.setState({ isMenuOpen: !isMenuOpen });
  };

  render() {
    const { isMenuOpen } = this.state;
    return (
      <header className={classnames('menu-main', { open: isMenuOpen })}>
        {/* <button className="menu-button" onClick={this.toggleMenu} type="button">
          <span className="button-label">
            {isMenuOpen === false ? 'Open' : 'Close'}
          </span>
          <span className="hamburger" />
        </button> */}
        <nav className="nav">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/calendar/">Calendar</Link>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}

export default Menu;
