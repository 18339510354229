import React from 'react';
import PropTypes from 'prop-types';

import './modal.scss';
import backIcon from './back.svg';

const Modal = props => {
  const { handleClose } = props;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <button onClick={handleClose}>
          <img src={backIcon} alt="Go back" />
        </button>
        <div className="modal-content">{props.children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Modal;
